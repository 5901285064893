import { render, staticRenderFns } from "./SubProviderDetail.vue?vue&type=template&id=7dd4cfc1&scoped=true&"
import script from "./SubProviderDetail.vue?vue&type=script&lang=js&"
export * from "./SubProviderDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7dd4cfc1",
  null
  
)

export default component.exports